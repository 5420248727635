<template>
  <ModalForm title="Coordonnées bancaires" @close-modal="$emit('close-modal')">
    <template #body>
      <fieldset class="modal-fieldset">
        <label class="modal-fieldset__label" for="name-owner-input">
          Titulaire du compte *
        </label>
        <FormInput
          placeholder="Marc Dupont"
          id="name-owner-input"
          name="name-owner-input"
          v-model="ownerName"
          data-cy="name-owner-input"
          :focus="false"
        ></FormInput>
        <p v-if="ownerInputError" class="modal-fieldset__error">
          Le nom du titulaire du compte doit comporter plus de deux caractères.
        </p>
      </fieldset>
      <fieldset class="modal-fieldset">
        <label class="modal-fieldset__label" for="iban-input">IBAN *</label>
        <FormInput
          placeholder="FRkk BBB BGGG GGCC CCCC CCCC CKK"
          id="iban-input"
          name="iban-input"
          v-model="ibanInput"
          data-cy="iban-input"
          :focus="true"
        ></FormInput>
        <p v-if="ibanInputError" class="modal-fieldset__error" data-cy="iban-error">
          Le format de cet IBAN n'est pas valide.
        </p>
      </fieldset>
      <fieldset class="modal-fieldset">
        <label class="modal-fieldset__label" for="bic-input">BIC *</label>
        <FormInput
          placeholder="LBDFRPPMXXX"
          id="bic-input"
          name="bic-input"
          v-model="bicInput"
          data-cy="bic-input"
          :focus="true"
        ></FormInput>
        <p v-if="bicInputError" class="modal-fieldset__error" data-cy="bic-error">
          Le format du BIC n'est pas valide.
        </p>
      </fieldset>
      <div class="modal-submit" @mouseover="updateErrorMessages">
        <div class="modal-submit__hover-error">
          {{ formErrors }}
        </div>
        <button
          :disabled="isButtonDisabled"
          class="btn btn-tertiary font-roboto"
          @click="sendBankingData"
          data-cy="form-button"
        >
          Valider
        </button>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import { mapGetters } from "vuex"
import ModalForm from "@/components/modal/ModalForm"
export default {
  components: {
    ModalForm,
  },
  props: {
    thirdPartyId: {
      type: Number,
    },
    bankingData: {
      type: [Object, String],
    },
  },
  data() {
    return {
      bankingDataId: null,
      ownerName: "",
      ibanInput: "",
      bicInput: "",
      ownerInputError: false,
      ibanInputError: false,
      bicInputError: false,
      formErrors: "",
    }
  },
  methods: {
    isOwnerNameValid() {
      return this.ownerName.length > 1
    },
    isIbanInputValid() {
      const ibanInputLengthWithoutSpace = this.ibanInput.split(" ").join("").length
      return ibanInputLengthWithoutSpace >= 14 && ibanInputLengthWithoutSpace <= 34
    },
    isBicInputValid() {
      return this.bicInput.length === 8 || this.bicInput.length === 11
    },
    areAllInputsValid() {
      return (
        this.isOwnerNameValid() && this.isIbanInputValid() && this.isBicInputValid()
      )
    },
    async sendBankingData() {
      if (this.areAllInputsValid()) {
        const payload = {
          thirdPartyId: this.thirdPartyId,
          data: {
            id: this.bankingDataId,
            owner: this.ownerName,
            iban: this.ibanInput,
            bic: this.bicInput,
          },
          dataToCreate: this.bankingData === "Données bancaires manquantes",
        }
        await this.$store.dispatch("thirdParties/handleThirdPartyBankingData", payload)
      }

      this.closeOrDisplayError()
    },
    closeOrDisplayError() {
      if (this.hasUpdateErrors(this.thirdPartyId)) {
        const errors = this.getUpdateErrors(this.thirdPartyId)
        this.ownerInputError = errors.hasOwnProperty("owner")
        this.ibanInputError = errors.hasOwnProperty("iban")
        this.bicInputError = errors.hasOwnProperty("bic")
      } else {
        this.$emit("close-modal")
      }
    },
    updateErrorMessages() {
      this.formErrors =
        this.bicErrorMessage + this.ibanErrorMessage + this.ownerNameErrorMessage
    },
  },
  computed: {
    ...mapGetters("thirdParties", ["getUpdateErrors", "hasUpdateErrors"]),
    isButtonDisabled() {
      return !this.areAllInputsValid()
    },
    bicErrorMessage() {
      return !this.isBicInputValid() ? "Le format du BIC n'est pas valide. " : ""
    },
    ibanErrorMessage() {
      return !this.isIbanInputValid() ? "Le format de l'IBAN n'est pas valide. " : ""
    },
    ownerNameErrorMessage() {
      return !this.isOwnerNameValid()
        ? "Le nom du titulaire du compte doit comporter plus de deux caractères. "
        : ""
    },
  },
  created() {
    if (this.bankingData !== "Données bancaires manquantes") {
      this.ownerName = this.bankingData.owner
      this.ibanInput = this.bankingData.iban
      this.bicInput = this.bankingData.bic
      this.bankingDataId = this.bankingData.id
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-fieldset {
  margin-top: 25px;
  width: 70%;

  &__label {
    font-family: Effra, sans-serif;
    font-size: 20px;
  }

  &__error {
    margin: 5px 0 0 5px;
    font-size: 14px;
    color: red;
  }
}

.modal-submit {
  position: relative;
  display: flex;
  justify-content: end;
  margin-top: 60px;

  &__hover-error {
    visibility: hidden;
  }

  &:hover .modal-submit__hover-error {
    position: absolute;
    visibility: visible;
    top: -20px;
    color: red;
    font-size: 12px;
  }
}
</style>
