import AbstractService from "../AbstractService"
import dayjs from "dayjs"
import fr from "dayjs/locale/fr"
import en from "dayjs/locale/en"

class DateUtils extends AbstractService {
  constructor() {
    super()
    dayjs.locale(fr)
  }

  now() {
    return dayjs()
  }

  /**
   * @param date
   * @param date2 optional
   * @returns {number}
   */
  diffDaysBetweenDates(date, date2 = this.now()) {
    return dayjs(date).diff(date2, "days")
  }

  /**
   * @param date
   * @param format
   * @param localLanguage
   * @returns {string}
   */
  format(date, format = "dd/mm/YYYY", localLanguage = "fr") {
    localLanguage === "en" ? dayjs.locale(en) : dayjs.locale(fr)
    return dayjs(date).format(format)
  }
}

let DateService = new DateUtils()

export default DateService
