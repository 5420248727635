import { render, staticRenderFns } from "./CheckboxRow.vue?vue&type=template&id=133094c4&scoped=true&"
import script from "./CheckboxRow.vue?vue&type=script&lang=js&"
export * from "./CheckboxRow.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxRow.vue?vue&type=style&index=0&id=133094c4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133094c4",
  null
  
)

export default component.exports