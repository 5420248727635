<template>
  <spinner v-if="!isLoggedIn" :no-message="true"></spinner>
  <div class="mainlayout" v-else>
    <div class="mainlayout-header">
      <burger-menu
        :visible="showMenu"
        @closeMenu="showMenu = false"
        @toggle-menu-visibility="showMenu = !showMenu"
      ></burger-menu>
      <top-bar
        @toggle-menu-visibility="showMenu = !showMenu"
        :expanded="showMenu"
        :key="$router.currentRoute.meta.breadcrumb"
      ></top-bar>
    </div>
    <div
      v-if="!isFullLayout"
      :style="layoutBgColor"
      class="mainlayout-content container-fluid frieze h-100"
    >
      <transition name="fade" mode="out-in">
        <content-header
          v-if="$router.currentRoute.meta.contentTitle"
          :key="$router.currentRoute.meta.contentTitle"
        ></content-header>
      </transition>
      <transition name="fade" mode="out-in">
        <router-view class="mh-100" :key="$route.fullPath"></router-view>
      </transition>
    </div>
    <div v-else>
      <transition name="fade" mode="out-in">
        <router-view class="mh-100" :key="$route.fullPath"></router-view>
      </transition>
    </div>
    <div v-if="$router.currentRoute.name !== 'home'" class="mainlayout-footer">
      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import LayoutFooter from "@/layouts/components/LayoutFooter"
import TopBar from "@/layouts/components/TopBar"
import BurgerMenu from "@/layouts/components/BurgerMenu"
import ContentHeader from "@/layouts/components/ContentHeader"
import SsoService from "@/services/technical/SsoService"
import LayoutMixin from "../mixins/LayoutMixin"

export default {
  name: "Layout",
  components: { LayoutFooter, TopBar, BurgerMenu, ContentHeader },
  mixins: [LayoutMixin],
  computed: {
    isLoggedIn() {
      return SsoService.isAuthenticated()
    },
  },
  watch: {
    $route() {
      // refresh token if necessary on route change
      SsoService.refreshToken()
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  created() {
    document.title = "Caarea Finance"
  },
}
</script>
