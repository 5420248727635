<template>
  <section>
    <Title title="Liste des tiers bénéficiaires" />
    <FilterList
      :value="displayValueSearchTextInput"
      @third-party-to-filter="searchThirdParty"
    />
    <ListThirdParties
      :third-parties-info="getThirdPartiesList"
      @third-party-id-to-display="openThirdPartyCard"
    />
    <PagingList
      :results-length="getThirdPartiesList.length"
      :page="page"
      :total-results="getCountResults"
      :number-index-to-display="4"
      @display-page-index="updateResultsInStore($event, searchText)"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import Title from "@/components/utils/TitleView"
import FilterList from "@/components/thirdParties/thirdPartiesFilter"
import ListThirdParties from "@/components/thirdParties/ListThirdParties"
import PagingList from "@/components/utils/PagingList"

export default {
  components: {
    Title,
    FilterList,
    ListThirdParties,
    PagingList,
  },
  props: {
    pageMemory: {
      type: Number,
      default: 1,
    },
    searchTextMemory: {
      type: String,
    },
  },
  data() {
    return {
      searchText: null,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("thirdParties", ["getThirdPartiesList", "getCountResults"]),
    displayValueSearchTextInput() {
      return this.searchTextMemory ? this.searchTextMemory : this.searchText
    },
  },
  methods: {
    searchThirdParty(thirdPartyToFilter) {
      this.searchText = thirdPartyToFilter
      this.updateResultsInStore(1, thirdPartyToFilter)
    },
    async updateResultsInStore(indexPage, searchText) {
      this.page = indexPage
      this.searchText = searchText
      const params = {
        pageResult: indexPage,
      }
      if (searchText) {
        params.search_text = searchText
      }

      this.setFilterLocalStorage(this.page, this.searchText)
      await this.$store.dispatch("thirdParties/setListThirdParties", params)
    },
    setFilterLocalStorage(page, textSearch) {
      localStorage.setItem(
        "filter-info",
        JSON.stringify({
          page,
          textSearch,
        })
      )
    },
    openThirdPartyCard(id) {
      this.$router.push({
        name: "third-party-details",
        params: {
          default: true,
          cardId: id,
          page: this.page,
          searchText: this.searchText,
        },
      })
    },
  },
  async created() {
    if (localStorage.getItem("filter-info")) {
      const filterInformations = JSON.parse(localStorage.getItem("filter-info"))
      await this.updateResultsInStore(
        filterInformations.page,
        filterInformations.textSearch
      )
      localStorage.removeItem("filter-info")
    } else {
      await this.updateResultsInStore(this.pageMemory, this.searchTextMemory)
    }
  },
}
</script>

<style lang="scss" scoped></style>
