import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import config from "@/store/config"
import payment from "@/store/payment"
import thirdParties from "@/store/thirdParties"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    config,
    thirdParties,
    payment,
    thirdParties,
  },
})
