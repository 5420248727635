<template>
  <div v-if="resultsLength > 0" class="paging font-gilroy">
    <div class="paging__indexes">
      <span
        v-if="page > 2 && indexToDisplay[0] > 1"
        class="icon-chevron-left paging__index-item"
        @click="onPageIndexClick(indexToDisplay[0] - 1)"
        data-cy="paging-before"
      ></span>
      <span
        v-for="pageIndex in listPageIndexes"
        :key="`${pageIndex}-page-index`"
        class="mouse-pointer paging__index-item"
        :class="page === pageIndex ? 'paging__index-item--is-selected' : null"
        @click="onPageIndexClick(pageIndex)"
        :data-cy="`index-${pageIndex}`"
      >
        {{ pageIndex }}
      </span>
      <span
        v-if="page < totalPages - 2"
        class="icon-chevron-right paging__index-item"
        @click="onPageIndexClick(indexToDisplay[indexToDisplay.length - 1] + 1)"
        data-cy="paging-after"
      ></span>
    </div>
    <div class="paging__page-informations font-effra-normal" data-cy="position-pages">
      Résultats: {{ page * 10 - 9 }} - {{ numberPageLastResult }} sur
      {{ totalResults }}
    </div>
  </div>
  <div class="paging paging__no-result" v-else data-cy="no-result">
    {{ $t("pricingRange.no_result") }}
  </div>
</template>

<script>
export default {
  props: {
    resultsLength: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    numberIndexToDisplay: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    async onPageIndexClick(index) {
      this.$emit("display-page-index", index)
    },
  },
  computed: {
    listPageIndexes() {
      return this.indexToDisplay
    },
    numberPageLastResult() {
      return Math.min(this.page * 10, this.totalResults)
    },
    indexToDisplay() {
      let gapIndex = 1
      if (this.page !== 1 && this.page !== this.totalPages) {
        gapIndex = this.page - 1
      }
      if (this.page === this.totalPages && this.page > 2) {
        gapIndex = this.totalPages - 2
      }
      const numberPageToDisplay = Math.min(this.numberIndexToDisplay, this.totalPages)
      return [...Array(numberPageToDisplay).keys()].map((n) => n + gapIndex)
    },
    totalPages() {
      return Math.ceil(this.totalResults / 10)
    },
  },
}
</script>

<style lang="scss" scoped>
.paging {
  padding-top: 40px;
  color: $bleucaarea;
  font-size: 16px;

  &__indexes,
  &__page-informations,
  &__no-result {
    display: flex;
    justify-content: center;
  }

  &__page-informations {
    margin-top: 5px;
    font-size: 13px;
  }

  &__index-item {
    cursor: pointer;
    height: 25px;
    width: 25px;
    line-height: 27px;
    text-align: center;

    &--is-selected {
      background-color: $bleucaarea;
      color: white;
      border-radius: 50%;
    }
  }
}
</style>
