import button from "./button.json"
import home from "./views/home.json"
import footer from "./layouts/footer.json"
import error from "./views/error.json"
import burgermenu from "./layouts/burgermenu.json"
import spinner from "./spinner.json"
import breadcrumb from "./breadcrumb.json"
import payment from "./views/payment.json"
import pricingRange from "./views/pricingRange.json"
import caareavlib from "../../../node_modules/caarea-vlib/src/i18n/fr"

export default {
  caareavlib,
  button,
  error,
  home,
  footer,
  burgermenu,
  spinner,
  breadcrumb,
  payment,
  pricingRange,
}
