<template>
  <div class="crit-option-selection">
    <label class="crit-option-selection__label" :for="getId">
      <input
        class="crit-option-selection__label__checkbox"
        :id="getId"
        type="checkbox"
        :checked="selected"
        @change="onCheck($event.target.checked)"
        :disabled="disabled"
      />
      <span
        class="crit-option-selection__checkmark"
        :class="{ 'crit-option-selection__checkmark-disabled': disabled }"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxRow",
  props: {
    selected: { type: Boolean, required: false },
    id: { type: [Number, String], required: true },
    disabled: { type: Boolean },
  },
  computed: {
    getId() {
      return this.id
    },
  },
  methods: {
    onCheck(value) {
      this.$emit("update:selected", this.id)
    },
  },
}
</script>

<style scoped lang="scss">
.crit-option-selection {
  height: 3rem;
  align-items: center;
  position: relative;
  user-select: none;
}

.crit-option-selection__label {
  color: $bleucaarea;
  cursor: pointer;
  // Due to this f.... bootstrap !
  margin-bottom: 0;
}

.crit-option-selection__label__checkbox {
  display: none;
}

.crit-option-selection__label__checkbox:checked ~ .crit-option-selection__checkmark {
  background-color: $bleucaarea !important;
  border-color: $bleucaarea;
  color: white;
}

.crit-option-selection__checkmark {
  border: 1px solid $bleucaarea;
  border-radius: 4px;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.crit-option-selection__checkmark-disabled {
  border: 1px solid #cbcbcb;
  cursor: default;

  &:hover:after {
    content: "Vous ne pouvez cocher que les sinistres à payer";
    position: absolute;
    top: 25px;
    width: 120px;
    height: 40px;
    font-size: 10px;
    background-color: #dadada;
    border-radius: 5px;
    padding: 5px;
  }
}

.crit-option-selection__label__checkbox:checked
  ~ .crit-option-selection__checkmark:after {
  display: block;
  content: "";
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  // Use the same checkmark svg as the one used in FormCheckbox (from Bootstrap !)
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' %3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.crit-option-selection__checkmark:after {
  height: 100%;
  position: absolute;
  width: 100%;
}
</style>
