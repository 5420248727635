<template>
  <div>
    <div
      class="modal fade"
      :class="{ show: show }"
      @click="onClose"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-form__box" role="document" @click.stop>
        <div class="modal-form__content">
          <div class="modal-form__header">
            <h5 class="modal-form__header--title" data-cy="modal-title">{{ title }}</h5>
            <div
              v-if="showCloseButton"
              class="modal-form__header--cross icon-cross"
              @click="onClose"
              data-cy="modal-close-cross"
            ></div>
          </div>
          <div class="modal-form__body">
            <div class="container-fluid">
              <slot name="body">
                <p>Modal body text goes here.</p>
              </slot>
            </div>
          </div>
          <div class="modal-footer" v-if="$slots.footer">
            <slot name="footer">
              <p>Modal footer text goes here.</p>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: show }"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    onClose() {
      this.$emit("close-modal")
    },
  },
  async mounted() {
    const el = document.body
    el.classList.add("modal-open")
    el.style["padding-right"] = "15px"
    setTimeout(() => (this.show = true), 50)
  },
  destroyed() {
    const el = document.body
    el.classList.remove("modal-open")
    el.style["padding-right"] = null
  },
}
</script>

<style scoped lang="scss">
.modal {
  display: block;
}

.modal-form__box {
  max-width: 900px;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}

.modal-form__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}

.modal-form__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  color: white;
  background-color: $primary;

  &--title {
    font-size: 24px;
    font-family: Effra, sans-serif;
    letter-spacing: 0.15px;
  }

  &--cross {
    font-size: 20px;
  }
}

.modal-form__body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  margin: 30px;
}
</style>
