<template>
  <section>
    <Title
      title="Règlement des évènements"
      subtitle="Déclenchez les paiements des réparations des sinistres"
    />
    <PaymentFilter
      @update-options-selected-filter="filterByStatus"
      @handle-checkall="handleCheckbox"
    />
    <PaymentList
      :payments="paymentsList.results"
      :checkbox-checked="allCheckboxChecked"
      @update:selected="handlePaymentClaims"
      data-cy="paging"
    />
    <footer>
      <PagingList
        class="paging"
        :results-length="totalItemsInPage"
        :page="page"
        :total-results="totalItemsResult"
        :number-index-to-display="4"
        @display-page-index="handlePageResult($event, statusPayment)"
      />
      <button
        data-cy="trigger-transfer"
        class="btn btn-tertiary font-roboto"
        :disabled="claimsChecked.length === 0"
      >
        {{ $t("payment.paymentAction") }}
      </button>
    </footer>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import PaymentList from "@/components/payment/PaymentList"
import PaymentFilter from "@/components/payment/PaymentFilter"
import PagingList from "@/components/utils/PagingList"
import Title from "@/components/utils/TitleView"

export default {
  components: {
    PaymentList,
    PaymentFilter,
    PagingList,
    Title,
  },
  data() {
    return {
      paymentsList: {},
      page: 1,
      statusPayment: [],
      claimsChecked: [],
      allCheckboxChecked: false,
    }
  },
  computed: {
    ...mapGetters("payment", ["getClaimsPaymentsList"]),
    totalItemsResult() {
      return this.paymentsList.count ? this.paymentsList.count : 0
    },
    totalItemsInPage() {
      return this.paymentsList.results ? this.paymentsList.results.length : 0
    },
  },
  methods: {
    filterByStatus(status) {
      this.handlePageResult(1, status)
    },
    async handlePageResult(index, status) {
      const statusPayment = status.length > 0 ? status.join(",") : ""
      await this.$store.dispatch("payment/getPaymentsStatus", {
        pageResult: index,
        statusPayment: statusPayment,
      })
      this.paymentsList = await this.modifyClaimsStatusIfNull(
        this.getClaimsPaymentsList
      )
      this.page = index
    },
    handlePaymentClaims(idClaims) {
      const indexClaimToRemove = this.claimsChecked.indexOf(idClaims)

      if (indexClaimToRemove === -1) {
        this.claimsChecked.push(idClaims)
      } else {
        this.claimsChecked.splice(indexClaimToRemove, 1)
      }
    },
    handleCheckbox() {
      this.allCheckboxChecked = !this.allCheckboxChecked
      this.claimsChecked = this.allCheckboxChecked ? this.listAllIdClaimsOverdue() : []
    },
    listAllIdClaimsOverdue() {
      return this.paymentsList.results
        .filter((res) => res.status === "Overdue")
        .map((res) => res.cbx_event_id)
    },
    modifyClaimsStatusIfNull(claims) {
      claims["results"].forEach((claim) => {
        if (claim.status === "null") {
          claim.status = this.getThirdPartyBankingDataSatus(claim)
        }
      })
      return claims
    },
    getThirdPartyBankingDataSatus(claim) {
      if (claim.third_party.banking_data === "Données bancaires manquantes") {
        return claim.third_party.banking_data
      } else {
        return "Données bancaires enregistrées"
      }
    },
  },
  async created() {
    await this.$store.dispatch("payment/getPaymentsStatus")
    this.paymentsList = this.modifyClaimsStatusIfNull(this.getClaimsPaymentsList)
  },
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  background-color: transparent;

  & .paging {
    width: 200px;
    margin: auto;
  }

  & button {
    position: absolute;
    top: 12px;
    right: 0;
  }
}
</style>
