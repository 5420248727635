<template>
  <header class="header__title font-effra-light">
    <div>
      <p data-cy="header-subtitle" class="header__title--subtitle" v-if="subtitle">
        {{ subtitle }}
      </p>
      <h2 data-cy="header-title" class="header__title--title">{{ title }}</h2>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.header__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-left: 5px solid $bleucaarea;
  height: 90px;
  margin-bottom: 40px;
  letter-spacing: 0.1rem;
  padding-left: 35px;
  &--subtitle {
    margin: 20px 0 0 0;
    font-size: 14px;
  }
  &--title {
    text-transform: uppercase;
    margin: 0;
    font-size: 22px;
  }
}
</style>
