<template>
  <section>
    <ModalBankingData
      v-if="showBankingDataModal"
      @close-modal="showBankingDataModal = false"
      :third-party-id="Number(cardId)"
      :banking-data="getThirdPartyDetails.banking_data"
    ></ModalBankingData>
    <span
      class="button-back icon-arrow-left"
      data-cy="return-button"
      @click="goPreviousPage"
    >
      <span class="button-back__text">Retour</span>
    </span>
    <div v-if="getThirdPartyDetails.id">
      <Title class="title" :title="headerTitle" :subtitle="headerSubtitle" />
      <CardThirdParty
        :details="getThirdPartyDetails"
        @open-modal="showBankingDataModal = true"
      />
    </div>
    <div v-else class="card__error">
      Le tiers bénéficiaire que vous cherchez n'existe pas
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import Title from "@/components/utils/TitleView"
import CardThirdParty from "@/components/thirdParties/CardThirdParty"
import ModalBankingData from "@/components/thirdParties/ModalBankingData"
export default {
  components: {
    CardThirdParty,
    Title,
    ModalBankingData,
  },
  data() {
    return {
      detailsThirdParty: {},
      showBankingDataModal: false,
    }
  },
  props: {
    cardId: {
      type: [Number, String],
      required: true,
    },
    page: {
      type: Number,
    },
    searchText: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("thirdParties", ["getThirdPartyDetails"]),
    headerTitle() {
      return this.getThirdPartyDetails.name ? this.getThirdPartyDetails.name : ""
    },
    headerSubtitle() {
      return this.getThirdPartyDetails.cbx_third_party_id
        ? `#Id ${this.getThirdPartyDetails.cbx_third_party_id}`
        : ""
    },
  },
  methods: {
    goPreviousPage() {
      this.$router.push({
        name: "third_parties_home",
        params: {
          pageMemory: this.page,
          searchTextMemory: this.searchText,
        },
      })
    },
  },
  async created() {
    await this.$store.dispatch("thirdParties/setThirdPartyDetails", this.cardId)
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 60px;
}

.button-back {
  position: absolute;
  font-family: "Effra-light", sans-serif;
  font-size: 18px;
  top: 190px;
  left: 70px;
  cursor: pointer;

  &__text {
    margin-left: 10px;
  }
}

.card__error {
  margin-top: 30px;
  background-color: white;
  padding: 20px;
}

@media (max-width: 1100px) {
  section {
    margin: 0 60px;
  }
}

@media (max-width: 900px) {
  section {
    margin: 0 10px;
  }
}
</style>
