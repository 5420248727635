// STATES (snake_case)
import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = {
  third_parties: [],
  count_results: null,
  third_party_details: {},
  update_or_create_banking_data_errors: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_LIST_THIRD_PARTIES(state, payload) {
    state.third_parties = payload
  },
  SET_COUNT_LIST(state, payload) {
    state.count_results = payload
  },
  SET_DETAILS_THIRD_PARTY(state, payload) {
    state.third_party_details = payload
  },
  UPDATE_THIRD_PARTY_BANKING_DATA(state, payload) {
    state.third_party_details.banking_data =
      Object.keys(payload).length === 0 ? "Données bancaires manquantes" : payload
  },
  RESET_UPDATE_OR_CREATE_BANKING_DATA_ERRORS(state, remove_id) {
    state.update_or_create_banking_data_errors = Object.keys(
      state.update_or_create_banking_data_errors
    ).reduce((acc, id) => {
      if (parseInt(id) !== parseInt(remove_id)) {
        acc[id] = state.update_or_create_banking_data_errors[id]
      }
      return acc
    }, {})
  },
  SET_UPDATE_OR_CREATE_BANKING_DATA_ERRORS(state, payload) {
    Vue.set(state.update_or_create_banking_data_errors, payload.id, payload.errors)
  },
}

// ACTIONS (camelCase)
const actions = {
  async setListThirdParties({ commit }, params) {
    try {
      let query = {}
      if (params && params.pageResult) {
        query.page = params.pageResult
      }
      if (params && params.search_text) {
        query.search_text = params.search_text
      }
      const thirdParties = await HttpService.get(
        UrlService.render("thirdPartiesApi", {}, query)
      )
      commit("SET_COUNT_LIST", thirdParties.count ? thirdParties.count : 0)
      commit("SET_LIST_THIRD_PARTIES", thirdParties.results)
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 404) {
          commit("SET_LIST_THIRD_PARTIES", [])
          return
        }
      }
      console.error("failed: ", e)
      throw e
    }
  },
  async setThirdPartyDetails({ state, commit }, id) {
    try {
      let thirdPartyDetails = state.third_parties.find((res) => res.id === id)
      if (!thirdPartyDetails) {
        thirdPartyDetails = await HttpService.get(
          UrlService.render("thirdPartyDetailsApi", { id: id })
        )
      }
      commit("SET_DETAILS_THIRD_PARTY", thirdPartyDetails)
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 404) {
          commit("SET_DETAILS_THIRD_PARTY", {})
          return
        }
      }
      console.error("failed: ", e)
      throw e
    }
  },
  async handleThirdPartyBankingData({ commit, dispatch }, payload) {
    try {
      commit("RESET_UPDATE_OR_CREATE_BANKING_DATA_ERRORS", payload.thirdPartyId)

      payload.dataToCreate
        ? await dispatch("createThirdPartyBankingData", payload)
        : await dispatch("updateThirdPartyBankingData", payload)
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422) {
          commit("SET_UPDATE_OR_CREATE_BANKING_DATA_ERRORS", {
            id: payload.thirdPartyId,
            errors: e.data,
          })
          return
        }
      }
      console.error("failed: ", e)
      throw e
    }
  },
  async createThirdPartyBankingData({ commit, dispatch }, payload) {
    const thirdPartyWithBankingData = await HttpService.post(
      UrlService.render("createBankingData", { id: payload.thirdPartyId }),
      payload.data
    )
    commit("SET_DETAILS_THIRD_PARTY", thirdPartyWithBankingData)
  },
  async updateThirdPartyBankingData({ commit, dispatch }, payload) {
    const bankingDataUpdated = await HttpService.put(
      UrlService.render("updateBankingData", { id: payload.data.id }),
      payload.data
    )
    commit("UPDATE_THIRD_PARTY_BANKING_DATA", bankingDataUpdated)
  },
}

// GETTERS (camelCase)
const getters = {
  getThirdPartiesList: (state) => state.third_parties,
  getThirdPartyDetails: (state) => state.third_party_details,
  getCountResults: (state) => state.count_results,
  getUpdateErrors: (state) => (id) =>
    state.update_or_create_banking_data_errors.hasOwnProperty(id)
      ? state.update_or_create_banking_data_errors[id]
      : null,
  hasUpdateErrors: (state, getters) => (id) => getters.getUpdateErrors(id) !== null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
