<template>
  <main class="card-third-party font-effra-light">
    <div class="card-third-party__block">
      <h3 class="card-third-party__block--title">Informations</h3>
      <div class="card-third-party__block--section">
        <p data-cy="card-id">Id: {{ details.cbx_third_party_id }}</p>
        <p data-cy="card-type">Type: {{ details.type }}</p>
      </div>
      <h4 class="card-third-party__block--label">{{ details.name }}</h4>
      <div>
        <p data-cy="card-address">{{ details.address_1 }}</p>
        <p data-cy="card-city">{{ details.city }}</p>
        <p data-cy="card-email">{{ details.email }}</p>
      </div>
    </div>
    <div class="card-third-party__block">
      <span
        v-if="bankingDataAvailable"
        class="card-third-party__block--icon icon-pencil5"
        @click="openModalBankingDataForm"
        data-cy="update-banking-data"
      ></span>
      <h3 class="card-third-party__block--title">Coordonnées bancaires</h3>
      <div v-if="bankingDataAvailable">
        <p data-cy="card-update-date" class="card-third-party__block--updateinfo">
          date de mise à jour {{ dateUpdate }}
        </p>
        <h4 class="card-third-party__block--label">Titulaire du compte</h4>
        <p data-cy="card-owner">
          {{ details.banking_data.owner }}
        </p>
        <h4 class="card-third-party__block--label">IBAN</h4>
        <p data-cy="card-iban">
          {{ details.banking_data.iban }}
        </p>
        <h4 class="card-third-party__block--label">BIC</h4>
        <p data-cy="card-bic">
          {{ details.banking_data.bic }}
        </p>
      </div>
      <div class="card-third-party__no-data" v-else>
        <div class="card-third-party__no-data--warning">
          données bancaires manquantes
        </div>
        <div
          class="card-third-party__no-data--add"
          data-cy="add-banking-data"
          @click="openModalBankingDataForm"
        >
          ajouter les données bancaires
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import DateService from "@/services/technical/DateService"

export default {
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openModalBankingDataForm() {
      this.$emit("open-modal")
    },
  },
  computed: {
    dateUpdate() {
      return DateService.format(
        this.details.banking_data.updated_at,
        "DD/MM/YYYY",
        "fr"
      )
    },
    bankingDataAvailable() {
      return this.details.banking_data !== "Données bancaires manquantes"
    },
  },
}
</script>
<style lang="scss" scoped>
.card-third-party {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  &__block {
    position: relative;
    padding: 20px;
    background-color: white;

    p {
      margin: 2px 0;
      font-size: 18px;
    }

    &--icon {
      position: absolute;
      margin: 25px;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    &--title {
      margin: 0;
      font-size: 13px;
      text-transform: uppercase;
    }

    &--section {
      margin: 20px 0;
    }

    &--label {
      margin: 0;
      font-size: 18px;
    }
  }

  p.card-third-party__block--updateinfo {
    padding-bottom: 20px;
    font-size: 11px !important;
  }

  &__no-data {
    margin-top: 40px;

    &--warning {
      padding-bottom: 30px;
      @include banking-data-context($error, 14px, "!");
    }

    &--add {
      @include banking-data-context($bleucaarea, 14px, "+");
      cursor: pointer;
    }
  }
}
</style>
