import Vue from "vue"
import VueRouter from "vue-router"
import CypressAuth from "@/views/CypressAuth"
import Home from "@/views/Home"
import Payment from "@/views/Payment"
import DetailsThirdParty from "@/views/DetailsThirdParty"
import ThirdParties from "@/views/ThirdParties"
import Layout from "@/layouts/Layout"
import NoLayout from "@/layouts/LayoutAuth"
import guards from "./guards"
import Page403 from "@/views/errors/Page403"
import Page404 from "@/views/errors/Page404"
import Page500 from "@/views/errors/Page500"
import GroupService from "@/services/business/GroupService"

Vue.use(VueRouter)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        beforeEnter: guards.requireAuth,
        redirect: { name: "home" },
        component: Layout,
        meta: { breadcrumb: "breadcrumb.home" },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            meta: {
              breadcrumb: "breadcrumb.home",
              maxGroupId: GroupService.BENEFICIARY,
              fullLayout: true,
            },
          },
          {
            path: "administration",
            name: "administration",
            beforeEnter: guards.requireAcceptedCookies,
            redirect: { name: "payments" },
            meta: {
              maxGroupId: GroupService.BENEFICIARY,
            },
            component: {
              render(c) {
                return c("router-view")
              },
            },
            children: [
              {
                path: "payment",
                name: "payments",
                component: Payment,
                meta: {
                  breadcrumb: "breadcrumb.paymentManagement",
                },
              },
              {
                path: "third_parties",
                name: "third_parties",
                props: true,
                redirect: { name: "third_parties_home" },
                meta: {
                  breadcrumb: "breadcrumb.thirdParties",
                },
                component: {
                  render(c) {
                    return c("router-view")
                  },
                },
                children: [
                  {
                    path: "",
                    name: "third_parties_home",
                    props: true,
                    component: ThirdParties,
                  },
                  {
                    path: ":cardId/details",
                    name: "third-party-details",
                    props: true,
                    component: DetailsThirdParty,
                    meta: {
                      breadcrumb: "breadcrumb.thirdPartyDetails",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "",
        redirect: { name: "home" },
        name: "publicZone",
        component: NoLayout,
        children: [
          {
            beforeEnter: guards.logout,
            path: "logout",
            name: "logout",
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoLayout,
        children: [
          {
            path: "403",
            name: "403",
            component: Page403,
          },
          {
            path: "404/:message?",
            name: "404",
            component: Page404,
            props: true,
          },
          {
            path: "500",
            name: "500",
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: CypressAuth,
  })
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
