<template>
  <div class="row-informations">
    <InformationItemList
      v-for="info in informationsToDisplay"
      :key="info.label"
      :label="info.label"
      :value="info.value"
    />
    <span
      :data-cy="`chevron-${thirdPartyInformations.id}`"
      class="row-informations__chevron icon-chevron-right-circle"
      @click="$emit('third-party-id-to-display', thirdPartyInformations.id)"
    ></span>
  </div>
</template>

<script>
import InformationItemList from "@/components/utils/InformationItemList"

export default {
  components: {
    InformationItemList,
  },
  props: {
    thirdPartyInformations: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    informationsToDisplay() {
      return [
        {
          label: "#id",
          value: this.thirdPartyInformations.cbx_third_party_id,
        },
        {
          label: "Nom",
          value: this.thirdPartyInformations.name,
        },
        {
          label: "Type",
          value: this.thirdPartyInformations.type,
        },
        {
          label: "Adresse",
          value: this.thirdPartyInformations.address_1,
        },
        {
          label: "Ville",
          value: this.thirdPartyInformations.city,
        },
        {
          label: "Email de contact",
          value: this.thirdPartyInformations.email,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.row-informations {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 2fr 1fr 2fr 0.5fr;
  grid-column-gap: 20px;
  border-top: $bleuc100 1px solid;
  padding: 10px;

  &__chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>
