<template>
  <main data-cy="third-parties">
    <RowThirdParty
      v-for="info in thirdPartiesInfo"
      :key="info.id"
      :third-party-informations="info"
      @third-party-id-to-display="handleThirdPartyIdToDisplay"
    />
  </main>
</template>

<script>
import RowThirdParty from "@/components/thirdParties/RowThirdParty"
export default {
  components: {
    RowThirdParty,
  },
  props: {
    thirdPartiesInfo: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleThirdPartyIdToDisplay(id) {
      this.$emit("third-party-id-to-display", id)
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  background-color: white;
  margin: 0 !important;
}
</style>
