// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import GroupService from "@/services/business/GroupService"
import SsoService from "@/services/technical/SsoService"
import AxiosHelper from "@/helpers/axios.helper"

const state = {
  current_user: null,
  current_user_group: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CURRENT_USER(state, user) {
    state.current_user = user
  },
  SET_CURRENT_USER_GROUP(state, group) {
    state.current_user_group = group
  },
  RESET_AUTH(state) {
    AxiosHelper.resetAuthorizationHeader()
    state.current_user = null
    state.current_user_group = null
  },
}

// ACTIONS (camelCase)
const actions = {
  async fetchLoggedUser({ commit, getters }, payload) {
    try {
      let user = await HttpService.get(UrlService.render("fetchLoggedUser"))
      commit("SET_CURRENT_USER", user)
      commit("SET_CURRENT_USER_GROUP", user.group)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async acceptCookies({ commit, getters }, userId) {
    const payload = {
      accepted_cookies: true,
    }
    const user = await HttpService.put(
      UrlService.render("userAcceptCookies", { id: userId }),
      payload
    )
    commit("SET_CURRENT_USER", user)
  },
  async logout({ commit }) {
    commit("RESET_AUTH")
    await SsoService.logout()
  },
}

// GETTERS (camelCase)
const getters = {
  isLoggedIn: (state, getters) =>
    (state.sharing_token && getters.isGuestUser) || SsoService.isAuthenticated(),
  isGuestUser: (state) =>
    state.current_user && state.current_user.group.id === GroupService.GUEST,
  getCurrentUser: (state) => state.current_user,
  getCurrentUserGroupId: (state) => state.current_user.group.id,
  areCookiesAccepted: (state) => state.current_user.profile.accepted_cookies,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
