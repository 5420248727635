<template>
  <div class="filter">
    <div></div>
    <FormInput
      class="filter__input-text"
      name="filter"
      @input="searchByText"
      @keyboard-enter="searchByText"
      placeholder="Recherchez un tiers"
      :value="value"
    />
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    searchByText(text) {
      this.$emit("third-party-to-filter", text)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  background-color: #e6e9ee;
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 2fr 1fr 2fr 0.5fr;
  grid-column-gap: 20px;
  margin-bottom: 5px;

  &__input-text {
    padding: 7px 0;
  }
}
</style>
