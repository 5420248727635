import AbstractService from "@/services/AbstractService"

class NumberUtils extends AbstractService {
  /**
   * Returns a decimal number with only one tenth
   * @param number
   * @returns {number}
   */
  convertNumberWithOneTenth(numberToConvert) {
    return Math.round(numberToConvert * 10) / 10
  }
  /**
   * Returns a string with devise symbole and a comma instead of point
   * @param number, devise
   * @returns {string}
   */
  convertNumberToPrice(number, devise) {
    return `${number} ${devise}`.replace(".", ",")
  }
}

let NumberService = new NumberUtils()
export default NumberService
