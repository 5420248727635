<template>
  <div class="item-information">
    <p class="item-information__label font-effra-light">{{ label }}</p>
    <p class="item-information__value font-gilroy">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
  },
}
</script>

<style lang="scss" scoped>
.item-information {
  &__label {
    line-height: 5px;
    font-size: 11px;
    margin: 5px 0;
  }
  &__value {
    display: inline-block;
    line-height: 15px;
    margin: 15px 0 20px 0;
    font-size: 16px;
  }
}
</style>
