<template>
  <div class="filter" data-cy="filter">
    <CheckboxRow id="all" @update:selected="checkAll" data-cy="checkbox-all" />
    <FormSelect
      class="filter__multiselect"
      :select-options="selectOptions"
      :selected-option.sync="selectedOption"
      label-select-attr="label"
      label="label"
      placeholder="Tous"
      name="claim-status"
      :errors="{}"
      :allow-empty="true"
      :multiple="true"
      @update:selected-option="updateFilter"
    />
  </div>
</template>

<script>
import CheckboxRow from "@/components/payment/CheckboxRow"
export default {
  components: {
    CheckboxRow,
  },
  data() {
    return {
      selectOptions: {
        Overdue: "à payer",
        Not_due: "non payable",
        Requested_transfer: "virement demandé",
        Wrong_request: "demande erronée",
        Paid: "payé",
        Banking_data_ok: "données bancaires enregistrées",
        Missing_banking_data: "données bancaires manquantes",
      },
      selectedOption: [],
    }
  },
  methods: {
    checkAll() {
      this.$emit("handle-checkall")
    },
    updateFilter(values) {
      values = values.map((value) => value.split("_").join(" "))
      this.$emit("update-options-selected-filter", values)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1.5fr 1.5fr 1.5fr 1fr 2fr;
  grid-column-gap: 20px;
  background-color: #e6e9ee;
  width: 100%;
  margin: 10px 0;
  padding: 7px;

  &__multiselect {
    grid-column: 7;
    width: 100%;
  }

  & div {
    justify-self: end;
  }
}
</style>
