// STATES (snake_case)
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

const state = {
  claims_payments: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_LIST_CLAIMS(state, payload) {
    state.claims_payments = payload
  },
}

// ACTIONS (camelCase)
const actions = {
  async getPaymentsStatus({ commit }, params) {
    try {
      let query = {}
      if (params) {
        query = {
          page: params.pageResult,
          status: params.statusPayment,
        }
      }

      const claimsPayments = await HttpService.get(
        UrlService.render("claimsPayments", {}, query)
      )
      commit("SET_LIST_CLAIMS", claimsPayments)
    } catch (e) {
      console.error(e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  getClaimsPaymentsList: (state) => JSON.parse(JSON.stringify(state.claims_payments)),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
