<template>
  <div>
    <p class="font-effra-light label">{{ label }}</p>
    <p class="font-gilroy value" :class="state">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
  },
  computed: {
    state() {
      switch (this.value) {
        case "Données bancaires manquantes":
          return "warning red"
          break
        case "non payable":
          return "warning red"
          break
        case "virement demandé":
          return "warning orange"
          break
        case "demande erronée":
          return "warning red"
          break
        case "payé":
          return "payment-state paid"
          break
        case "à payer":
          return "payment-state overdue"
          break
        case "données bancaires enregistrées":
          return "payment-state data-ok"
          break
        default:
          return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  line-height: 5px;
  font-size: 12px;
  font-weight: 600;
  margin: 5px 0;
}
.value {
  display: inline-block;
  line-height: 15px;
  margin: 15px 0 20px 0;
  font-size: 16px;
}
.payment-state {
  position: relative;
  bottom: 5px;
  border-radius: 9px;
  padding: 5px 30px 5px 10px;
}

.overdue,
.data-ok {
  background-color: #01a39d;
  color: white;
}

.paid {
  background-color: $bleuc100;
}

.warning {
  position: relative;
  bottom: 5px;
  font-size: 9px;

  &:before {
    content: "!";
    display: inline-block;
    border: 1px solid red;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    line-height: 20px;
    text-align: center;
    vertical-align: center;
    font-size: 1rem;
    margin-right: 5px;
  }
}

.red {
  color: $error;
  &:before {
    border-color: $error;
  }
}

.orange {
  color: $dark-orange;
  &:before {
    border-color: $dark-orange;
  }
}
</style>
