<template>
  <div class="payment-row">
    <CheckboxRow
      :id="payment.cbx_event_id"
      :disabled="isDisabled"
      @update:selected="selectPayment"
      :selected="itemChecked"
    />
    <PaymentInformation
      v-for="data in rowDatas"
      :key="data.label"
      :label="data.label"
      :value="data.value"
    />
  </div>
</template>

<script>
import PaymentInformation from "@/components/payment/PaymentInformation"
import CheckboxRow from "@/components/payment/CheckboxRow"
import NumberService from "@/services/technical/NumberService"

export default {
  components: {
    PaymentInformation,
    CheckboxRow,
  },
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
    checkboxChecked: {
      type: Boolean,
    },
  },
  data() {
    return {
      rowDatas: [
        {
          label: this.$t("payment.label.claim"),
          value: this.payment.cbx_event_id,
        },
        {
          label: this.$t("payment.label.beneficiary"),
          value: this.payment.beneficiary_fullname,
        },
        {
          label: this.$t("payment.label.garage"),
          value: this.payment.third_party.name,
        },
        {
          label: this.$t("payment.label.account"),
          value: this.payment.partner,
        },
        {
          label: this.$t("payment.label.amount"),
          value: this.convertAmountWithOneTenth(this.payment.amount),
        },
        {
          label: this.$t("payment.label.state"),
          value: this.$t(`payment.value.${this.payment.status}`),
        },
      ],
    }
  },
  computed: {
    isDisabled() {
      return this.payment.status !== "Overdue"
    },
    itemChecked() {
      return !this.isDisabled && this.checkboxChecked
    },
  },
  methods: {
    selectPayment(idClaims) {
      this.$emit("update:selected", idClaims)
    },
    convertAmountWithOneTenth(numberToConvert) {
      const roundedAmount = NumberService.convertNumberWithOneTenth(numberToConvert)
      return NumberService.convertNumberToPrice(roundedAmount, "€")
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-row {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1.5fr 1.5fr 1.5fr 1fr 2fr;
  grid-column-gap: 20px;
  border-top: $bleuc100 1px solid;
  padding: 7px;
}
</style>
