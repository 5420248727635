<template>
  <section class="payments-list">
    <PaymentRow
      v-for="payment in payments"
      :key="payment.cbx_event_id"
      :payment="payment"
      :checkbox-checked="checkboxChecked"
      @update:selected="selectPayment"
    />
  </section>
</template>

<script>
import PaymentRow from "@/components/payment/PaymentRow"

export default {
  components: {
    PaymentRow,
  },
  props: {
    payments: {
      type: Array,
      default: () => [],
    },
    checkboxChecked: {
      type: Boolean,
    },
  },
  methods: {
    selectPayment(idClaims) {
      this.$emit("update:selected", idClaims)
    },
  },
}
</script>

<style>
.payments-list {
  background-color: white;
  margin: 0 !important;
}
</style>
